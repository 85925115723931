export const drawerWidth = 260;

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';

export const FIREBASE_API = {
  apiKey: 'AIzaSyC74w_JCigORyepa_esLkPt-B3HgtI_X3o',
  authDomain: 'mantis-4040b.firebaseapp.com',
  projectId: 'mantis-4040b',
  storageBucket: 'mantis-4040b.appspot.com',
  messagingSenderId: '1073498457348',
  appId: '1:1073498457348:web:268210e18c8f2cab30fc51',
  measurementId: 'G-7SP8EXFS48'
};

export const AWS_API = {
  poolId: 'us-east-1_AOfOTXLvD',
  appClientId: '3eau2osduslvb7vks3vsh9t7b0'
};

export const JWT_API = {
  secret: 'SECRET-KEY',
  timeout: '1 days'
};

export const AUTH0_API = {
  client_id: '7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V',
  domain: 'dev-w0-vxep3.us.auth0.com'
};
// ==============================|| THEME CONFIG  ||============================== //
const config = {
  appName: "TICX",
  appVersion: process.env.REACT_APP_VERSION || 'v0.0.0',
  basename: '/',
  companyName: "ConscienceX Co.,Ltd.",
  releasedYear: 2022,
  defaultPath: '/result/dashboard', 
  displayThemePreview: true,
  enabledLogs: (process.env.REACT_APP_ENABLED_LOGS === "false" ||
    process.env.REACT_APP_ENABLED_LOGS === "" ||
    !process.env.REACT_APP_ENABLED_LOGS) ? false: true,
  fontFamily: `'Noto Sans', sans-serif`,
  i18n: 'en',
  nodeEnv: process.env.NODE_ENV || 'development',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  relative: {
    endpoint: `${process.env.REACT_APP_RELATIVE_ENDPOINT || "http://localhost:8080"}`,
  },
  server: {
    API_ENDPOINT: `${process.env.REACT_APP_API_HOST || "http://localhost:8080"}`,
    GQL_API_ENDPOINT: `${process.env.REACT_APP_GRAPHQL_HOST || "http://localhost:8080"}/graphql`,
    GQL_WS_ENDPOINT: `${process.env.REACT_APP_GRAPHQL_HOST?.replace('https', 'ws') || "ws://localhost:8080"}/graphql`,
  },
  geolocation: {
    DEFAULT_API_ENDPOINT: `${process.env.REACT_APP_GEOLOC_DEFAULT_API || ''}/json`,
    API_ENDPOINT: `${process.env.REACT_APP_GEOLOC_API_HOST || ''}/ipgeo`,
    API_KEY: `?apiKey=${process.env.REACT_APP_GEOLOC_API_KEY || ''}`,
  },
  sentry: {
    API_ENDPOINT: process.env.REACT_APP_SENTRY_ENDPOINT? process.env.REACT_APP_SENTRY_ENDPOINT : null,
  },
  themeDirection: 'ltr',
  websiteUrl: "https://www.ticx-consciencex.com/",
};

export default config;
